import { AnimatePresence, motion } from "framer-motion";
import { FiAlertCircle, FiImage } from "react-icons/fi";
import { FaImage, FaVideo, FaFileAudio, FaXmark } from "react-icons/fa6";
import { Dialog, DialogPanel } from "@headlessui/react";
import React, { useState } from "react";

const useModal = (initState) => {
  const [modalOpen, setModalOpen] = useState(initState || false);

  return {
    modalOpen,
    setModalOpen
  }
}

const Icon = ({ icon }) => {
  if (!icon) { return <FiAlertCircle className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" /> }
  if (icon === 'image') { return <FaImage className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-16 -left-16" /> }
  if (icon === 'video') { return <FaVideo className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-16 -left-16" /> }
  if (icon === 'audio') { return <FaFileAudio className="text-slate-900/10 rotate-12 text-[250px] absolute z-0 -top-16 -left-16" /> }
}

const Modal = ({ modalOpen, setModalOpen, children, icon, maxWidth}) => {
  const maxW = maxWidth || "max-w-7xl"

  return (
    <AnimatePresence>
      {modalOpen && (
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          as="div"
          className='bg-slate-900/20 backdrop-blur p-2 fixed inset-0 z-40 overflow-y-scroll'
        >
          <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <motion.div
              initial={{ scale: 0, rotate: "12.5deg" }}
              animate={{ scale: 1, rotate: "0deg" }}
              exit={{ opacity: 0 }}
              className="flex min-h-full items-center justify-center p-4"
            >
              <div className={`bg-gradient-to-br from-ssbeige to-ssbeige-alt text-slate-900 p-6 rounded-lg w-full shadow-xl cursor-default relative overflow-hidden ${maxW}`} >
                <Icon icon={icon} />
                <FaXmark onClick={() => setModalOpen(false)} className="cursor-pointer hover:text-slate-700 text-slate-900 w-6 h-6 absolute z-10 top-5 right-5" />
                <DialogPanel className="relative">
                  { React.Children.map(children, (child) => {
                    return (child && <> { child } </>)
                  })}
                </DialogPanel>
              </div>
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export { Modal, useModal };
